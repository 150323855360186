var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("h1", [_vm._v(_vm._s(_vm.title))]),
        _c("router-link", { attrs: { to: "/" } }, [_vm._v("Top")]),
        _c(
          "form",
          {
            staticClass: "needs-validation",
            attrs: { novalidate: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.exec($event)
              }
            }
          },
          [
            _vm.errors.length > 0
              ? _c(
                  "ul",
                  { staticClass: "alert" },
                  _vm._l(_vm.errors, function(error) {
                    return _c("li", [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              : _vm._e(),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.sampleName,
                    expression: "sampleName"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: _vm.defaultSampleName },
                domProps: { value: _vm.sampleName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.sampleName = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mail,
                    expression: "mail"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "mail" },
                domProps: { value: _vm.mail },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.mail = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password1,
                    expression: "password1"
                  }
                ],
                ref: "password1",
                staticClass: "form-control",
                attrs: {
                  type: "password",
                  placeholder: "パスワード",
                  required: ""
                },
                domProps: { value: _vm.password1 },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password1 = $event.target.value
                  }
                }
              }),
              _c("div", [_vm._v(_vm._s(_vm.textCount) + "/10")]),
              _c("div", { ref: "pwerror", staticClass: "invalid-feedback" }, [
                _vm._v("入力してください")
              ])
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password2,
                    expression: "password2"
                  }
                ],
                ref: "password2",
                staticClass: "form-control",
                attrs: {
                  type: "password",
                  placeholder: "パスワード",
                  required: ""
                },
                domProps: { value: _vm.password2 },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password2 = $event.target.value
                  }
                }
              })
            ]),
            _vm._m(0),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "form-check form-check-inline" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.radio1,
                      expression: "radio1"
                    }
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    id: "radio01",
                    type: "radio",
                    name: "radiogroup",
                    value: "r1",
                    required: ""
                  },
                  domProps: { checked: _vm._q(_vm.radio1, "r1") },
                  on: {
                    change: function($event) {
                      _vm.radio1 = "r1"
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label",
                    attrs: { for: "radio01" }
                  },
                  [_vm._v("選択肢１")]
                )
              ]),
              _c("div", { staticClass: "form-check form-check-inline" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.radio1,
                      expression: "radio1"
                    }
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    id: "radio02",
                    type: "radio",
                    name: "radiogroup",
                    value: "r2",
                    required: ""
                  },
                  domProps: { checked: _vm._q(_vm.radio1, "r2") },
                  on: {
                    change: function($event) {
                      _vm.radio1 = "r2"
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label",
                    attrs: { for: "radio02" }
                  },
                  [_vm._v("選択肢2")]
                )
              ]),
              _c(
                "div",
                { ref: "radioerror", staticClass: "invalid-feedback" },
                [_vm._v("選択してください")]
              )
            ]),
            _vm._m(1)
          ]
        ),
        _c("div", [_vm._v(_vm._s(_vm.message))]),
        _c("div", { staticClass: "mt-5" }, [_vm._v("ネストコンポーネント")]),
        _c(
          "div",
          [
            _c("router-link", { attrs: { to: "/sample/sample/sub1" } }, [
              _vm._v("sub1")
            ]),
            _vm._v("|"),
            _c("router-link", { attrs: { to: "/sample/sample/sub2" } }, [
              _vm._v("sub2")
            ])
          ],
          1
        ),
        _c("div", { staticClass: "mt-1" }, [_c("router-view")], 1)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "form-check" }, [
        _c("input", {
          staticClass: "form-check-input",
          attrs: { id: "check01", type: "checkbox" }
        }),
        _c(
          "label",
          { staticClass: "form-check-label", attrs: { for: "check01" } },
          [_vm._v("選択肢１")]
        )
      ]),
      _c("div", { staticClass: "form-check" }, [
        _c("input", {
          staticClass: "form-check-input",
          attrs: { id: "check02", type: "checkbox" }
        }),
        _c(
          "label",
          { staticClass: "form-check-label", attrs: { for: "check02" } },
          [_vm._v("選択肢2")]
        )
      ]),
      _c("div", { staticClass: "invalid-feedback" }, [
        _vm._v("選択してください")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("input", {
        staticClass: "btn btn-primary",
        attrs: { type: "submit", value: "Exec" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }