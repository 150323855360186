<template lang="pug">
  div
    .container
      h1 {{title}}
      router-link(to="/") Top
      form.needs-validation(@submit.prevent="exec" novalidate)
        ul.alert(v-if="errors.length > 0")
          li(v-for="error in errors") {{error}}
        .form-group
          input.form-control(type="text" v-bind:placeholder="defaultSampleName" v-model="sampleName")
        .form-group
          input.form-control(type="mail" v-model="mail")
        .form-group
          input.form-control(type="password" placeholder="パスワード" v-model="password1" ref="password1" required)
          div {{ textCount }}/10
          .invalid-feedback(ref="pwerror") 入力してください
        .form-group
          input.form-control(type="password" placeholder="パスワード" v-model="password2" ref="password2" required)
        .form-group
          .form-check
            input#check01.form-check-input(type="checkbox")
            label.form-check-label(for="check01") 選択肢１
          .form-check
            input#check02.form-check-input(type="checkbox")
            label.form-check-label(for="check02") 選択肢2
          .invalid-feedback 選択してください
        .form-group
          .form-check.form-check-inline
            input#radio01.form-check-input(type="radio" name="radiogroup" value="r1" v-model="radio1" required)
            label.form-check-label(for="radio01") 選択肢１
          .form-check.form-check-inline
            input#radio02.form-check-input(type="radio" name="radiogroup" value="r2" v-model="radio1" required)
            label.form-check-label(for="radio02") 選択肢2
          .invalid-feedback(ref="radioerror") 選択してください
        .form-group
          input.btn.btn-primary(type="submit" value="Exec")
      div {{message}}
      .mt-5 ネストコンポーネント
      div
        router-link(to="/sample/sample/sub1") sub1
        ||
        router-link(to="/sample/sample/sub2") sub2
      .mt-1
        router-view
      //- //- Button trigger modal
      //- button.btn.btn-primary(type="button" data-toggle="modal" data-target="#exampleModal") Launch demo modal
      //- //- Modal
      //- #exampleModal.modal.fade(tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true")
      //- .modal-dialog.role="document"
      //-   .modal-content
      //-     .modal-header
      //-       h5.modal-title#exampleModalLabel Modal title
      //-       button.close(type="button" data-dismiss="modal" aria-label="Close")
      //-         span(aria-hidden="true") &times
      //-     .modal-body ...
      //-     .modal-footer
      //-       button.btn.btn-secondary(type="button" data-dismiss="modal") Close
      //-       button.btn.btn-primary(type="button") Save changes
</template>

<script>

import {
  Config,
  CognitoIdentityCredentials
} from 'aws-sdk'
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js'
import { rejects } from 'assert'

export default {
  // データオブジェクト
  data() {
    return {
      // デフォルト値
      title: 'sample',
      defaultSampleName: 'サンプルテキスト',
      // 入力値
      sampleName: '',
      mail: '',
      password1: '',
      password2: '',
      radio1: '',
      // 出力値
      message: '',
      // エラーメッセージ
      errors: []
    }
  },
  // 算出プロパティ
  computed: {
    msg() { return this.sampleName ? this.sampleName + 'が入力されました' : '' },
    textCount() { return this.password1.length }
  },
  // メソッド
  methods: {
    exec(e) {
      this.message = this.msg
      this.errors = []

      e.target.classList.add('was-validated')

      if (this.password1 != this.password2) {
        this.$refs.password1.setCustomValidity(' ')
        this.$refs.pwerror.innerText = 'パスワードと確認用パスワードが一致していません。'
      } else {
        this.$refs.password1.setCustomValidity('')
        this.$refs.pwerror.innerText = '入力してください'
      }

      this.$refs.radioerror.style.display = (this.radio1) ? '' : 'block'

      if (!e.target.checkValidity()) {
        this.errors.push('エラーメッセージ')
        return
      }

      this.$router.push({ name: 'sample_sample2', params: { mail: this.mail }})
    }
  },
  // インスタンス作成後
  created() {
    console.log('created')
  },
  // マウント後
  mounted() {
    console.log('mounted')
    this.$nextTick(() => {
      // ビュー全体がレンダリングされた後にのみ実行されるコード
      console.log('nextTick')
    })
  },
  // データ変更後
  updated() {
    console.log('updated')
  },
  // インスタンス破棄前
  beforeDestroy() {
    console.log('beforeDestroy')
  }
}
</script>